<template >
  <ion-page>
    <Header type="0" title="零件补码"> </Header>

    <ion-content scroll-y="true" class="content">
      <div class="root">
        <div class="item">
          <div class="left">试制任务单号</div>
          <div class="right">
            <ion-input
              v-model="queryParam.stageCode"
              placeholder="请输入任务单号"
            ></ion-input>
          </div>
        </div>
        <div class="item">
          <div class="left">零件编号</div>
          <div class="right">
            <ion-input
              v-model="queryParam.partCode"
              placeholder="请输入零件编号"
            ></ion-input>
          </div>
        </div>
        <div class="item">
          <div class="left">零件名称</div>
          <div class="right">
            <ion-input
              v-model="queryParam.partNameCN"
              placeholder="请输入零件名称"
            ></ion-input>
          </div>
        </div>
        <div class="item">
          <div class="left">版本</div>
          <div class="right">
            <ion-input
              v-model="queryParam.partVersion"
              placeholder="请输入零件版本"
            ></ion-input>
          </div>
        </div>
        <div class="item">
          <ion-button class="selectBtn" @click="getList">查询</ion-button>
          <ion-button
            class="selectBtn"
            @click="clearQuery"
            style="margin-left: 10px"
            >重置</ion-button
          >
        </div>
      </div>

      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div
        v-for="item in tableList"
        :key="item.id"
        class="app-container"
        @click="openModal(item)"
      >
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
        >
          <div class="item-label">{{ item.partCode }}</div>
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        <div class="item">
          <div class="item-label">试制任务单号</div>
          <div class="item-content">{{ item.stageCode }}</div>
        </div>
      </div>
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { qrCode } from "ionicons/icons";
import { getPartInfo } from "@/api/storeManagement/partsComplement";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import SystemUtils from "@/utils/SystemUtils";
import printCodeModal from "./printCodeModal.vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
    Header,
    uploadImg,
    CardLoading,
    ionAlert,
    printCodeModal,
  },
  data() {
    return {
      qrCode,
      tableList: [],
      islist: false, // isloading
      isOpenRef: false, // 确认框
      queryParam: {
        stageCode:"",
        partNameCN:"",
        partCode:"",
        partVersion:"",
      },
    };
  },
  mounted() {},
  methods: {
    getList() {
      this.queryParam.stageCode = this.queryParam.stageCode.trim();
      this.queryParam.partNameCN = this.queryParam.partNameCN.trim();
      this.queryParam.partCode = this.queryParam.partCode.trim();
      this.queryParam.partVersion = this.queryParam.partVersion.trim();
      if (
        !this.queryParam.stageCode &&
        !this.queryParam.partNameCN &&
        !this.queryParam.partCode &&
        !this.queryParam.partVersion
      ) {
        Utils.presentToastTopWarning("至少输入一项查询条件", "danger");
        return false;
      }
      this.islist = true;
      
      getPartInfo(this.queryParam).then((res) => {
        this.islist = false;
        if (!res.data.isError && res.data.data.isSuccess) {
          this.tableList = res.data.data.resultData;
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    async openModal(part) {
      const modal = await modalController.create({
        component: printCodeModal,
        cssClass: "my-custom-class",
        componentProps: {
          part: part,
        },
      });
      return modal.present();
    },
    clearQuery() {
      this.tableList = [];
      this.queryParam = {stageCode:"",
        partNameCN:"",
        partCode:"",
        partVersion:"",};
    },
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
  .selectBtn {
    margin: 0 auto;
    width: 100%;
    height: 30px;
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
      }
    }
    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
.no_data {
  padding-top: 0;
}
</style>