<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>打印零件二维码</ion-title>
      <ion-buttons slot="end">
        <ion-button color="primary" @click="closeModal">
          <i class="iconfont icon-guanbi"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="app-container">
      <div
        class="item"
        style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
      >
        <div class="item-label">{{ part.partCode }}</div>
        <div class="title">{{ part.partNameCN }}</div>
        <div class="rate">{{ part.partVersion }}</div>
      </div>
      <div class="item">
        <div class="item-label">开始编号</div>
        <div class="item-content">
          <ion-input
            v-model.number="part.beginNum"
            placeholder="请输入开始编号"
            style="text-align: right; --padding-end: 0"
          ></ion-input>
        </div>
      </div>
      <div class="item">
        <div class="item-label">结束编号</div>
        <div class="item-content">
          <ion-input
            v-model.number="part.endNum"
            placeholder="请输入结束编号"
            style="text-align: right; --padding-end: 0"
          ></ion-input>
        </div>
      </div>
    </div>
  </ion-content>
  <div ref="appModal" class="canvasContent"></div>
  <ion-footer>
   
    <ion-button expand="block" @click="print">打印</ion-button>
  </ion-footer>
</template>

<script>
import { defineComponent } from "vue";
import { modalController, loadingController } from "@ionic/vue";
import { createTwoCode } from "@/api/storeManagement/partsComplement";
import Utils from "@/utils/Utils.ts";
export default defineComponent({
  name: "Modal",
  props: {
    part: { type: Object, default: {
      beginNum:'',
      endNum:''
    } },
  },
  data() {
    return {
      content: "Content",
      markList: [],
    };
  },
  methods: {
    async print() {
        if(!localStorage.getItem('printHttp')&&!localStorage.getItem('printPort')){
         Utils.presentToastTopWarning("当前设备没有设置打印机", "danger");
        return false
      }
      var _this = this;
     
      if (
        this.part.beginNum === "" ||
        this.part.beginNum == null ||
        this.part.beginNum == undefined
      ) {
        Utils.presentToastTopWarning("请输入开始编号", "danger");
        return false;
      }
       if (
        typeof this.part.beginNum == "string" 
      ) {
        Utils.presentToastTopWarning("开始编号应为数字", "danger");
        return false;
      } if (
        typeof this.part.endNum == "string" 
      ) {
        Utils.presentToastTopWarning("结束编号应为数字", "danger");
        return false;
      }
      if (
        this.part.endNum === "" ||
        this.part.endNum == null ||
        this.part.endNum == undefined
      ) {
        Utils.presentToastTopWarning("请输入结束编号", "danger");
        return false;
      }
      if (this.part.beginNum > this.part.endNum) {
        Utils.presentToastTopWarning("开始编号不能大于结束编号", "danger");
        return false;
      }
       var beginNum = this.part.beginNum
      var endNum = this.part.endNum
      beginNum = String(beginNum).trim()
      endNum = String(endNum).trim()
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });

      await loading.present();
      createTwoCode({
        id: this.part.id,
        beginNum: beginNum,
        endNum: endNum,
      }).then(async (res) => {
        loading.dismiss();

        if (!res.data.isError && res.data.data.isSuccess) {
          _this.markList = res.data.data.resultData;
          _this.pushOptions();
        } else {
          Utils.presentToastTopWarning(
            res.data.message || res.data.data.message,
            "danger"
          );
        }
      });
    },
    async pushOptions() {
      var str = "";

      this.markList.forEach((e, idx) => {
        str += `<div class="app-container">
                   <canvas class="imgCanvas" id="canvas${idx}"></canvas>
                </div>`;
      });

      this.$refs["appModal"].innerHTML = str;

      this.$forceUpdate();
      var _this = this;
      this.$nextTick(() => {
        for (var i = 0; i < _this.markList.length; i += 2) {
          (function (j) {
            var canvas = document.getElementById(`canvas${j}`);
            var context = canvas.getContext("2d");
            canvas.width = 1248;
            canvas.height = 350;
            context.fillStyle = "#fff";
            context.fillRect(0, 0, canvas.width, canvas.height);
            _this.drawCanvas(canvas, context, j, 10);
            if (j + 1 < _this.markList.length) {
              _this.drawCanvas(canvas, context, j + 1, 525);
            }
          })(i);
        }
      });
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "请稍等...",
        duration: 50000,
      });

      await loading.present();
      setTimeout(() => {
        loading.dismiss();
        Utils.presentToastTopWarning("打印成功", "success");
        _this.printCanvas();
      }, 1000);
    },
    drawCanvas(canvas, context, j, left) {
      var _this = this;

      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = _this.markList[j].codeUrl;
      //图片加载完后，将其显示在canvas中
      img.onload = function () {
        context.fillStyle = "#333";
        context.font = "20px bold Verdana";
        context.fillText("任务单号：" + _this.markList[j].stageCode, left, 245);
        context.fillText(
          "零件名称：" + _this.part.partNameCN,
          left,
          275
        ); //_this.markList[j].PARTNAMECN
        context.fillText("零件编号：" + _this.markList[j].partCode, left, 305);
        context.fillText(
          "零件版本：" + _this.markList[j].partVersion+"   "+"零件序号：" + _this.markList[j].partOrderNumber,
          left,
          335
        );
        // context.fillText(
        //   "零件序号：" + _this.markList[j].partOrderNumber,
        //   left,
        //   345
        // );
        context.drawImage(this, left+112, 20, 200, 200);
        canvas.style.background = "rgb(255, 255, 255)";
      };
    },
    async printCanvas() {
      var _this = this;
      for (let i = 0; i < _this.markList.length; i++) {
        if (i % 2 == 0) {
          (async function (j) {
            var dataBase = document.getElementById(`canvas${j}`).toDataURL();
            setTimeout(() => {
              window.ZebraPrinterAndroid.PrintImageBase64(
                localStorage.getItem('printHttp'),
                localStorage.getItem('printPort'),
                dataBase.slice(dataBase.indexOf(",") + 1),
                function () {
                  console.log("success");
                },
                function () {
                  Utils.presentToastWarning("打印失败");
                  console.log("fail");
                }
              );
            }, j * 5000 + 1000);
          })(i);
        }
      }
      modalController.dismiss();
    },
    closeModal() {
      modalController.dismiss();
    },
  },
});
</script>
<style lang="scss" scoped>
.sc-ion-buttons-ios-h {
  align-items: flex-end;
}
.app-container {
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
      }
    }
    .item-label {
      width: 60%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}
.iconfont {
}
</style>
<style lang="scss">
.canvasContent {
  display: none;
}
</style>